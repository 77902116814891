import "./sass/style.scss";
import "./js/resize.js";

import svgLogo from "./assets/LOGO-04.svg"
import svgInstagram from "./assets/instagram.svg"
import svgFacebook from "./assets/facebook.svg"

function createSvg(svg, id = "") {
  const elem = document.createElement("div");
  elem.innerHTML = svg;
  if (id) {
    elem.querySelector("svg").id = id
  }
  return elem.querySelector("svg")
}

function createLink(options) {
  const newElem = document.createElement("a");
  for (const prop in options) {
    switch (prop) {
      case "href":
        newElem.setAttribute("href", options[prop]);
        break;
      case "action":
        newElem.addEventListener("click", options[prop]);
        break;
      case "content":
        if (Array.isArray(options[prop])) {
          options[prop].forEach(child => newElem.append(child))
        } else {
          newElem.append(options[prop])
        }
        break;
      case "class":
        newElem.classList.add(options[prop]);
        break;
      case "target":
        newElem.setAttribute("target", options[prop])
    }
  }
  return newElem
}

document.getElementById("placeholder-logo").replaceWith(createSvg(svgLogo, "logo"));
document.querySelector(".svg-container").appendChild(createLink({
  "content": createSvg(svgInstagram),
  "class": "icon-link",
  "href": "https://www.instagram.com/studio.cutelo",
  "target": "_blank"
}));
// document.querySelector(".svg-container").appendChild(createLink({
//   "content": createSvg(svgFacebook),
//   "class": "icon-link",
//   "href": "https://www.instagram.com/studio.cutelo",
//   "target": "_blank"
// }));
document.getElementById("mailto").replaceWith(createLink({
  "content": "geral@studiocutelo.com",
  "class": "inline-link",
  "href": "mailto:geral@studiocutelo.com",
  "target": "_blank"
}))
document.getElementById("tel").replaceWith(createLink({
  "content": "(+351) 934 337 751",
  "class": "inline-link",
  "href": "tel:+351934337751"
}))